<template>
    <div class="add-new-formtype">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.formTypeUpdate')"
            :title="$t('messages.formTypeUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="formType" :resource-object="formType" :resource="$FormTypes"
                       base-url="/settings/form-types"
                       create-message="messages.formTypeCreated" update-message="messages.formTypeUpdated"
                       :action="action"
                       :emails="false" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../../Settings/components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            formType: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$FormTypes.getResource({id}).then((response) => {
                this.formType = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
